const locations = {
  BASE_URL: "https://api.uidu.shop/",
  companies: "manager/companies",
  branches: "manager/branches",
  products: "manager/products",
  cloneMenu: "clone-branch-menu",
  syncMenu: "sync-branch-menu",
  clearMenu: "clear-branch-menu",
  categories: "manager/product-categories",
  entregaFacil: "manager/entrega-facil-ifood",
  complementGroups: "manager/complementGroups",
  customers: "manager/customers",
  complements: "manager/complements",
  orders: "manager/orders",
  carts: "carts",
  pdvKeys: "pdv-keys",
  reviews: "reviews",
  erpStatus: "erp-status",
  customerMessages: "customers-messages",
  offers: "manager/offers",
  upload: "manager/upload",
  users: "users",
  drivers: "drivers",
  sessions: "customer-sessions",
  updateDeliveryStatus: "update-delivery-status",
  usersActivities: "users-activity",
  //IFOOD INTEGRATION
  ifoodAuth: "integrations/ifood-auth",
  ifoodOrders: "integrations/ifood-orders",
  //LOGISTIC ENDPOINTS
  LOGISTIC_BASE_URL: "https://logistica.wedo.inf.br/",
  zones: "api/v1/zone/list",
  getVehicles: "api/v1/get-vehicles",
  driversList: "api/v1/vendor/delivery-man/list",
  createDriver: "api/v1/vendor/delivery-man/store",
  assignDriver: "api/v1/vendor/delivery-man/assign-deliveryman",
  loggedVendorInfo: "api/v1/vendor/profile",
  vendorOrders: "api/v1/vendor/all-orders",
  orderDetails: "api/v1/vendor/order-details",
  placeOrder: "api/v1/vendor/pos/place-order",
  vendorLogin: "api/v1/auth/vendor/login",
};

export default locations;
