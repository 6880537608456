import React, { useState, useEffect } from "react";

import { Chip, Tooltip } from "@mui/material";
import { useApp } from "../../../context/appContext";

const OrderBranchChip = ({ branch }) => {
  const { state } = useApp();

  const branchTitle = state.branches.filter((b) => b._id === branch).length
    ? state.branches.filter((b) => b._id === branch)[0].name
    : " - ";

  return (
    <Tooltip title={branchTitle.toUpperCase()}>
      <Chip
        label={
          branchTitle.length > 15
            ? `${branchTitle.substring(0, 15)}...`
            : branchTitle
        }
        sx={{
          borderRadius: "5px",
          height: "24px",
          textTransform: "uppercase",
          fontSize: "11px",
          letterSpacing: "0.8px",
          marginTop: "5px",
        }}
      />
    </Tooltip>
  );
};

export default OrderBranchChip;
