import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://185.217.126.3:3030/",
});

api.defaults.timeout = 20000;

export default api;
