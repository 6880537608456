import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import authentication from "@feathersjs/authentication-client";
//http://185.217.126.3/

const socket = io(
  process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://185.217.126.3:3030/",
  {
    transports: ["websocket"],
    forceNew: true,
  }
);
const client = feathers();

client.configure(
  socketio(socket, {
    timeout: 15000,
  })
);

client.configure(
  authentication({
    storageKey: "feathers-jwt", // key used to store the token in storage
    jwtStrategy: "jwt", // the name of the JWT strategy on the server
    jwtOptions: {
      header: { typ: "access" }, // match the server's configuration
      audience: "https://yourdomain.com", // match the server's configuration
      algorithm: "HS256", // match the server's configuration
      expiresIn: "10d", // match the server's configuration
    },
  })
);

export default client;
export { socket };
