import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import Chart from "react-apexcharts";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import getDateRange from "../../../utils/getDataRange";
import getHoursRange from "../../../utils/getHoursRange";
import moment from "moment/moment";

const options = ["Action", "Another Action", "Something else here"];

const PeriodHeatMap = ({ ordersData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [chartSeries, setChartSeries] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function countOrdersByHourOfDayOfWeek(orders) {
    const result = [];

    const translatedDays = {
      Sunday: "Domingo",
      Monday: "Segunda",
      Tuesday: "Terça",
      Wednesday: "Quarta",
      Thursday: "Quinta",
      Friday: "Sexta",
      Saturday: "Sábado",
    };

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    for (const dayName of days) {
      result[dayName] = { name: dayName, data: Array(24).fill(0) };
      const ordersForDay = orders.filter((order) => {
        const orderDate = moment(order.createdAt);
        return orderDate.day() === days.indexOf(dayName);
      });
      // Count orders for each hour of the day
      ordersForDay.forEach((order) => {
        const orderHour = moment(order.createdAt).hour();
        result[dayName].data[orderHour]++; // Increment the count
      });
      result.push({
        name: translatedDays[dayName],
        data: formatData(result[dayName].data), // Assuming formatData is your function to format the data
      });
    }
    //console.log("hours result", result);
    return result;
  }

  const generateData = () => {
    //INTERVAL OF HOURS
    const endDate = moment().add(1, "days").format("YYYY-MM-DD");
    const initialDate = moment().subtract(1, "months").format("YYYY-MM-DD");

    //getHoursRange(initialDate, );

    //const dates = getDateRange(initialDate, endDate);
    //dates.pop();

    //console.log("dates", dates);
  };

  useEffect(() => {
    if (ordersData) {
      const hoursInterval = countOrdersByHourOfDayOfWeek(ordersData);
      setChartSeries(hoursInterval);
    }
  }, [ordersData]);

  var options = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    plotOptions: {
      heatmap: {
        colorScale: {
          ranges: [
            {
              from: -1,
              to: 0,
              color: "#4caf50",
              name: "sem pedidos",
            },
            {
              from: 1,
              to: 10,
              color: "#128FD9",
              name: "baixo movimento",
            },
            {
              from: 11,
              to: 20,
              color: "#FFB200",
              name: "médio movimento",
            },
            {
              from: 21,
              to: 100,
              color: "#FF0000",
              name: "alto movimento",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#008FFB"],
  };

  var series = [
    {
      name: "Domingo",
      data: formatData([
        0, 0, 5, 10, 35, 25, 30, 30, 5, 20, 0, 0, 15, 5, 20, 5, 40, 25, 20, 30,
        45, 20, 15, 5,
      ]),
    },
    {
      name: "Sábado",
      data: formatData([
        0, 0, 4, 0, 28, 28, 12, 28, 44, 28, 28, 8, 0, 8, 16, 32, 20, 36, 36, 44,
        28, 32, 4, 4,
      ]),
    },
    {
      name: "Sexta",
      data: formatData([
        0, 0, 0, 0, 20, 30, 45, 45, 30, 20, 5, 5, 15, 35, 35, 20, 45, 40, 25, 5,
        20, 25, 40, 5,
      ]),
    },
    {
      name: "Quinta",
      data: formatData([
        0, 0, 15, 10, 40, 25, 35, 25, 30, 10, 2, 5, 5, 10, 30, 30, 5, 15, 15, 5,
        55, 20, 20, 5,
      ]),
    },
    {
      name: "Quarta",
      data: formatData([
        0, 0, 5, 10, 35, 25, 30, 30, 5, 20, 0, 0, 15, 5, 20, 5, 40, 25, 20, 30,
        45, 20, 15, 5,
      ]),
    },
    {
      name: "Terça",
      data: formatData([
        0, 0, 4, 0, 28, 28, 12, 28, 44, 28, 28, 8, 0, 8, 16, 32, 20, 36, 36, 44,
        28, 32, 4, 4,
      ]),
    },
    {
      name: "Segunda",
      data: formatData([
        0, 4, 0, 4, 4, 0, 4, 12, 16, 24, 16, 8, 16, 4, 20, 32, 48, 44, 36, 36,
        90, 28, 8, 16,
      ]),
    },
  ];

  function formatData(data) {
    let newData = [];
    let categories = [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ];

    for (var i = 0; i < categories.length; i++) {
      newData.push({
        x: categories[i],
        y: data[i],
      });
    }
    //console.log(newData);
    return newData;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        pb: 0,
      }}
    >
      <CardContent
        sx={{
          pb: "0 !important",
        }}
      >
        {ordersData ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                mb: 5,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "h3.fontSize",
                    marginBottom: "0",
                  }}
                  gutterBottom
                >
                  Pedidos por Dia/Horário
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                  }}
                >
                  Pedidos por dia da semana
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "auto",
                }}
              >
                <IconButton
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertOutlinedIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {/* {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={handleClose}
                    >
                      {option}
                    </MenuItem>
                  ))} */}
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "15px",
              }}
            >
              {ordersData && chartSeries && (
                <Chart
                  options={options}
                  series={chartSeries}
                  type="heatmap"
                  height="350"
                />
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginBottom: "25px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PeriodHeatMap;
